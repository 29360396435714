export const MENU_ITEM_BOOKING = 'booking'
export const MENU_ITEM_SUBSCIPRION = 'subscription'
export const MENU_ITEM_CLINICS = 'clinics'
export const MENU_ITEM_DOCTORS = 'doctors'
export const MENU_ITEM_SERVICES = 'services'
export const MENU_ITEM_WDWT = 'wdwt'
export const MENU_ITEM_JOURNAL = 'journal'
export const MENU_ITEM_FEEDBACK = 'feedback'
export const MENU_ITEM_JOB = 'job'
export const MENU_ITEM_OFFICIAL = 'official'
export const MENU_ITEM_CORPORATE = 'corporate'
export const MENU_ITEM_TAX_DEDUCTION = 'tax-deduction'
export const MENU_ITEM_CORPORATE_SERVICE = 'corporate-service'


export const HOTMENU_ITEM_NOTSURE = 'not-sure'
export const HOTMENU_ITEM_BOOKING = 'booking'
export const HOTMENU_ITEM_SUBSCRIPTION = 'subscription'
export const HOTMENU_ITEM_ACCOUNT = 'account'
