var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('item-edit-view',{attrs:{"title":"Страница","subtitle":_vm.item ? _vm.item.title.ru : null,"loading":_vm.loading},scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('v-btn',{attrs:{"right":"","color":"success","loading":_vm.saving},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.save)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-save")]),_vm._v(" Сохранить ")],1)]},proxy:true}],null,true)},[_c('v-card',[_c('v-tabs',{attrs:{"background-color":"primary","dark":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{key:"main"},[_vm._v("Описание")]),_c('v-tab',{key:"content"},[_vm._v("Контент")]),_c('v-tab',{key:"openGraph"},[_vm._v("Open Graph")])],1),(!_vm.loading && _vm.item)?_c('v-form',[_c('v-card-text',[_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{key:"main"},[_c('h2',{staticClass:"text-h4 mb-2"},[_vm._v(" Основное ")]),_c('p',{staticClass:"control"},[_c('label',{staticClass:"checkbox"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.item.enabled),expression:"item.enabled"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.item.enabled)?_vm._i(_vm.item.enabled,null)>-1:(_vm.item.enabled)},on:{"change":function($event){var $$a=_vm.item.enabled,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.item, "enabled", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.item, "enabled", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.item, "enabled", $$c)}}}}),_vm._v(" Активно ")])]),_c('validation-provider',{attrs:{"name":"Название","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Название","error-messages":errors},model:{value:(_vm.item.name),callback:function ($$v) {_vm.$set(_vm.item, "name", $$v)},expression:"item.name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"uri","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"URI","error-messages":errors,"required":""},model:{value:(_vm.item.uri),callback:function ($$v) {_vm.$set(_vm.item, "uri", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.uri"}})]}}],null,true)}),_c('h2',{staticClass:"text-h4 mt-8 mb-2"},[_vm._v(" Описание ")]),_c('locale-field',{attrs:{"component":"v-text-field","label":"Заголовок страницы","name":"title","outlined":"","dense":"","hide-details":"","rules":"required"},model:{value:(_vm.item.title),callback:function ($$v) {_vm.$set(_vm.item, "title", $$v)},expression:"item.title"}}),_c('locale-field',{staticClass:"mt-4",attrs:{"component":"v-textarea","rows":2,"label":"Описание страницы","name":"description","outlined":"","dense":"","hide-details":""},model:{value:(_vm.item.description),callback:function ($$v) {_vm.$set(_vm.item, "description", $$v)},expression:"item.description"}}),_c('v-text-field',{staticClass:"mt-4 mb-4",attrs:{"outlined":"","dense":"","hide-details":"","type":"number","label":"priority"},model:{value:(_vm.item.priority),callback:function ($$v) {_vm.$set(_vm.item, "priority", $$v)},expression:"item.priority"}}),_c('v-text-field',{staticClass:"mb-4",attrs:{"dense":"","outlined":"","hide-details":"","label":"Ключевые слова"},model:{value:(_vm.item.keywords),callback:function ($$v) {_vm.$set(_vm.item, "keywords", $$v)},expression:"item.keywords"}}),_c('v-row',[_c('v-col',[_c('h2',{staticClass:"mb-4"},[_vm._v("Meta")]),_c('validation-provider',{attrs:{"slim":"","name":"meta","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('item-meta',{attrs:{"error":errors[0]},model:{value:(_vm.item.meta),callback:function ($$v) {_vm.$set(_vm.item, "meta", $$v)},expression:"item.meta"}})]}}],null,true)})],1)],1)],1),_c('v-tab-item',{attrs:{"ley":"content"}},[_c('locale-field',{attrs:{"component":"quill","options":_vm.contentConfig,"outlined":"","dense":"","hide-details":"","name":"body"},model:{value:(_vm.item.body),callback:function ($$v) {_vm.$set(_vm.item, "body", $$v)},expression:"item.body"}})],1),_c('v-tab-item',{key:"openGraph"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('locale-field',{staticClass:"mb-4",attrs:{"component":"v-text-field","outlined":"","dense":"","hide-details":"","label":"Заголовок","name":"openGraphTitle","rules":"required"},model:{value:(_vm.item.openGraphTitle),callback:function ($$v) {_vm.$set(_vm.item, "openGraphTitle", $$v)},expression:"item.openGraphTitle"}}),_c('locale-field',{attrs:{"outlined":"","dense":"","hide-details":"","component":"v-text-field","label":"Описание","name":"openGraphDescription","rules":"required"},model:{value:(_vm.item.openGraphDescription),callback:function ($$v) {_vm.$set(_vm.item, "openGraphDescription", $$v)},expression:"item.openGraphDescription"}}),_c('OpenGraphTwitterCardSelect',{staticClass:"mt-8",attrs:{"outlined":"","dense":"","label":"Twitter card"},model:{value:(_vm.item.openGraphTwitterCard),callback:function ($$v) {_vm.$set(_vm.item, "openGraphTwitterCard", $$v)},expression:"item.openGraphTwitterCard"}})],1),_c('v-col',[_c('ShareImageEditor',_vm._b({staticClass:"mt-6",on:{"input:image":function($event){_vm.item.openGraphImage = $event}}},'ShareImageEditor',{
                      title: _vm.item.openGraphTitle ? _vm.item.openGraphTitle.ru : null,
                      description: _vm.item.openGraphDescription ? _vm.item.openGraphDescription.ru : null,
                      image: _vm.item.openGraphImage
                    },false))],1)],1)],1)],1)],1)],1):_vm._e()],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }