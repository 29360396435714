<template>
  <v-select
    v-on="$listeners"
    v-bind="{
      ...$attrs,
      items,
      value,
    }"
  />
</template>

<script>
export default {
  props: {
    value: {}
  },
  computed: {
    items () {
      return [
        'summary',
        'summary_large_image'
      ]
    }
  }
}
</script>
