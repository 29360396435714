<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <item-edit-view
      title="Страница"
      :subtitle="item ? item.title.ru : null"
      :loading="loading"
    >
      <template v-slot:right>
        <v-btn
          right
          color="success"
          :loading="saving"
          @click.prevent="handleSubmit(save)"
        >
          <v-icon small>mdi-content-save</v-icon>
          Сохранить
        </v-btn>
      </template>

      <v-card>
        <v-tabs v-model="tab" background-color="primary" dark>
          <v-tab key="main">Описание</v-tab>
          <v-tab key="content">Контент</v-tab>
          <v-tab key="openGraph">Open Graph</v-tab>
        </v-tabs>

        <v-form v-if="!loading && item">
          <v-card-text>
            <v-tabs-items v-model="tab">
              <v-tab-item key="main">

                <h2 class="text-h4 mb-2">
                  Основное
                </h2>

                <p class="control">
                  <label class="checkbox">
                    <input type="checkbox" v-model="item.enabled">
                    Активно
                  </label>
                </p>

                <validation-provider
                  v-slot="{ errors }"
                  name="Название"
                  rules="required"
                >
                  <v-text-field
                    label="Название"
                    v-model="item.name"
                    :error-messages="errors"
                  />
                  <!-- <v-select
                    :items="menuItems"
                    label="Название"
                    v-model="item.name"
                    :error-messages="errors"
                  /> -->
                </validation-provider>

                <validation-provider
                  v-slot="{ errors }"
                  name="uri"
                  rules="required"
                >
                  <v-text-field
                    v-model.trim="item.uri"
                    label="URI"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>

                <h2 class="text-h4 mt-8 mb-2">
                  Описание
                </h2>

                <locale-field
                  component="v-text-field"
                  v-model="item.title"
                  label="Заголовок страницы"
                  name="title"
                  outlined
                  dense
                  hide-details
                  rules="required"
                />

                <locale-field
                  component="v-textarea"
                  :rows="2"
                  v-model="item.description"
                  label="Описание страницы"
                  name="description"
                  outlined
                  dense
                  hide-details
                  class="mt-4"
                />

                <v-text-field
                  outlined
                  dense
                  hide-details
                  class="mt-4 mb-4"
                  v-model="item.priority"
                  type="number"
                  label="priority"
                ></v-text-field>

                <v-text-field
                  dense
                  outlined
                  hide-details
                  v-model="item.keywords"
                  label="Ключевые слова"
                  class="mb-4"
                ></v-text-field>

                <v-row>
                  <v-col>
                    <h2 class="mb-4">Meta</h2>
                    <validation-provider slim name="meta" rules="required" v-slot="{ errors }">
                      <item-meta
                        v-model="item.meta"
                        :error="errors[0]"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>

              </v-tab-item>

              <v-tab-item ley="content">
                <locale-field
                  component="quill"
                  :options="contentConfig"
                  v-model="item.body"
                  outlined
                  dense
                  hide-details
                  name="body"
                ></locale-field>
              </v-tab-item>

              <v-tab-item key="openGraph">
                <v-row>
                  <v-col cols="6">
                    <locale-field
                      component="v-text-field"
                      outlined
                      dense
                      hide-details
                      v-model="item.openGraphTitle"
                      label="Заголовок"
                      name="openGraphTitle"
                      rules="required"
                      class="mb-4"
                    />

                    <locale-field
                      outlined
                      dense
                      hide-details
                      component="v-text-field"
                      v-model="item.openGraphDescription"
                      label="Описание"
                      name="openGraphDescription"
                      rules="required"
                    />
                    <OpenGraphTwitterCardSelect
                      v-model="item.openGraphTwitterCard"
                      outlined
                      dense
                      class="mt-8"
                      label="Twitter card"
                    />
                  </v-col>
                  <v-col>
                    <ShareImageEditor
                      class="mt-6"
                      v-bind="{
                        title: item.openGraphTitle ? item.openGraphTitle.ru : null,
                        description: item.openGraphDescription ? item.openGraphDescription.ru : null,
                        image: item.openGraphImage
                      }"
                      @input:image="item.openGraphImage = $event"
                    />
                  </v-col>
                </v-row>

              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-form>
      </v-card>
    </item-edit-view>
  </ValidationObserver>
</template>

<script>
import pagesApi from '../../services/pages'
import createOrUpdateViewMixin from '../../mixins/createOrUpdateView'
import * as menuItems from '../../../../ui/common/menuItems'
import LocaleField from '@/components/Locale/LocaleField.vue'
import OpenGraphTwitterCardSelect from '@/components/OpenGraph/OpenGraphTwitterCardSelect.vue'
import SettingsImagePicker from '@/components/Settings/SettingsImagePicker.vue'
import ShareImageEditor from '@/components/Share/ShareImageEditor.vue'
import { Notification } from 'element-ui'
import { QUILL_EXTENDED as contentConfig } from '@/components/elements/quilljs/configs'
import { ItemMeta } from '@/components'

export default {
  mixins: [createOrUpdateViewMixin(pagesApi)],
  components: {
    ItemMeta,
    Notification,
    SettingsImagePicker,
    LocaleField,
    OpenGraphTwitterCardSelect,
    ShareImageEditor
  },
  data() {
    return {
      contentConfig,
      menuItems: Object.values(menuItems),
      tab: null,
    }
  }
}
</script>
